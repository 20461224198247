<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <div class="langtoggle">
      <CDropdown :show.sync="showDropdown">
        <template #toggler>
          <CHeaderNavLink>
            <div class="c-avatar" style="width: 30px;height: 30px;">
              <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " v-if="$i18n.locale === 'th'" />
              <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " v-else />
            </div>
          </CHeaderNavLink>
        </template>
        <label>
          <CDropdownItem v-if="$i18n.locale === 'en'" @click="changeLocale('th')">
            <div class="c-avatar" style="width: 30px;height: 30px;">
              <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " @click="changeLocale('th')" />
            </div>
            <span style="margin-left: 20px;"> ภาษาไทย </span>
          </CDropdownItem>
        </label>
        <label>
          <CDropdownItem v-if="$i18n.locale === 'th'" @click="changeLocale('en')">
            <div class="c-avatar" style="width: 30px;height: 30px;">
              <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " @click="changeLocale('en')" />
            </div>
            <span style="margin-left: 20px;"> English </span>
          </CDropdownItem>
        </label>
      </CDropdown>
    </div>
    <CCol class="ml-1 mr-1">
      <CRow class="justify-content-center">
        <CCard color="white" text-color="dark" class="text-center col-md-4 shadow-sm" style="border-radius: 0px">
          <CCardBody>
            <CRow>
              <CCol md="12">
                <img src="/logo.png" alt="" class="img-fluid" />
                <br />
                <br />
                <h3 class="text-left font-weight-bold text-black mb-3">
                  {{ $t('loginAsStaff') }}
                </h3>

                <CForm @keyup.enter="login()">
                  <CInput v-model="username" type="text" :placeholder="$t('username')">
                  </CInput>
                  <CInput v-model="password" type="password" :placeholder="$t('password')">
                  </CInput>

                  <strong v-if="alert !== ''" class="text-danger float-left">
                    {{ alert }}
                  </strong>
                  <br />
                  <CRow>
                    <CCol col="12" v-if="loadingButton === true">
                      <CButton @click="login" block color="success" class="px-4">
                        {{ $t('login') }}
                      </CButton>
                    </CCol>
                    <CCol col="12" v-else-if="loadingButton === false">
                      <CButton block color="success" disabled>
                        <CSpinner color="white" size="sm" />
                      </CButton>
                    </CCol>
                  </CRow>
                  <router-link to="/cashier/resetpassword" style="text-decoration: none">
                    <p class="mt-3 text-left text-info">{{ $t('forgetPassword') }}</p>
                  </router-link>
                </CForm>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CRow>
    </CCol>
  </CContainer>
</template>

<script>
import auth from '@/services/auth'

export default {
  data() {
    return {
      username: '',
      password: '',
      alert: '',
      loadingButton: true,
      showDropdown: false,
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.showDropdown = false
    },
    login() {
      this.loadingButton = false

      let data = {
        user: this.username,
        password: this.password,
      }

      auth.post('/auth/v1.0/localuser/login', data).then((res) => {
        console.log(res.data)

        if (res.data.error.code === 0) {
          console.log(res.data.data)
          let token = res.data.data.token
          let uid = res.data.data.owner
          let shopObjectId = res.data.data.shopObjectId
          let user = res.data.data.user

          localStorage.setItem('token', token)
          localStorage.setItem('uid', uid)
          localStorage.setItem('x-shopObjectId', shopObjectId)
          localStorage.setItem('x-auth', user)
          this.alert = ''
          window.location.href = '/'
        } else {
          this.alert = res.data.error.message
        }
        this.loadingButton = true
      })
    },
  },
}
</script>
<style scoped>
.langtoggle {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2000;
  padding: 20px;
}
</style>
